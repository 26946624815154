<template>
  <div class="container">
    <div class="row">
      <div class="col-12 py-4">
        <div class="p-fluid container-fluid">
          <Button 
            class="p-button-raised p-button-rounded my-3 p-button-danger" 
            icon="pi pi-arrow-left" 
            @click="goBack"
          />

          <div style="display: flex; align-items: center; margin-bottom: 1.5rem;">
            <h3 style="margin-bottom: 0;" >ADD DOCUMENT</h3>
            <!-- <Button
              class="p-button-raised p-button-rounded my-3 p-button-help mx-3" 
              icon="pi pi-question-circle" 
              @click="goToHeaderPage('documentEdit-header')"
            /> -->
          </div>

          
          <!-- Information -->
          <Divider align="left">
            <div class="inline-flex align-items-center">
              <span class="p-tag">INFORMATION</span>
              <!-- <Button 
                  class="p-button-raised p-button-rounded p-button-help mx-3" 
                  icon="pi pi-question-circle" 
                  @click="goToHeaderPage('documentEdit-section1')" /> -->
            </div>
          </Divider>

          <div class="row" style="margin-left: 20px;">
            <div class="col-12 col-md-8">
              <div class="field my-3">
                <label for="title">Title</label>
                <InputText
                  id="title"
                  v-model.trim="newDocumentForm.title"
                  @blur="v$.newDocumentForm.title.$touch"
                  required="true"
                  autofocus
                  :class="{ 'p-invalid': !newDocumentForm.title }"
                />
                <small class="p-error" v-if="!newDocumentForm.title"
                  >Title is required.</small
                >
              </div>
            </div>
            <div class="col-12 col-md">
              <div class="field my-3" style="max-width: 400px">
                <label for="title">Document Date</label>
                <Calendar
                  v-model="newDocumentForm.documentDate"
                  dateFormat="dd/mm/yy"
                  required="true"
                  :class="{ 'p-invalid': !newDocumentForm.documentDate }"
                />
                <small class="p-error" v-if="!newDocumentForm.documentDate"
                  >Date is required.</small
                >
              </div>
            </div>
          </div>

          <div class="row" style="margin-left: 20px;">
            <!-- Source  -->
            <div class="col-12 col-md">
            <!-- <div class="col-12 col-md-4 col-xl-3"> -->
              <div class="field my-3">
                <label for="title">Document Source Name</label>
                <!-- // sourceName amend to DocumentSourcename -->
                <InputText v-model.trim="newDocumentForm.documentSourcename" />
              </div>
              <!-- <div class="col-12 col-md-4 col-xl-3"> -->
              <div class="field my-3">
                <label for="title">Document Source Type</label>
                <!-- // sourceId amend to DocumentSourceTypeId -->
                <Dropdown
                  v-model="newDocumentForm.documentSourceTypeId"
                  :options="documentSources"
                  optionLabel="name"
                  optionValue="documentSourceId"
                  placeholder="Document Source"
                />
              </div>
              
            </div>
            <!-- Applications and Notes -->
            <div class="col-12 col-md">
              <!-- Hide Application feat usability 11-01-2024 -->
              
              <!-- <div class="field my-3">
                <label for="title">Application</label>
                <MultiSelect
                  v-model="newDocumentForm.applications"
                  :options="documentApplications"
                  optionLabel="name"
                  optionValue="applicationTypeId"
                  placeholder="Select Application"
                  display="chip"
                  @change="validateApplicationOptions($event)"
                  @show="validateShowOptions($event)"
                  :showToggleAll="false"
                >
                  <template #option="slotProps">
                    <div
                      :id="
                        'application-option-' +
                        slotProps.option.applicationTypeId
                      "
                      class="p-multiselect-option hidden"
                    >
                      <span>{{ slotProps.option.name }}</span>
                    </div>
                  </template>
                </MultiSelect>
              </div> -->

              <div class="field my-3">
                <label for="notes">Notes</label>
                <Textarea id="notes" v-model.trim="newDocumentForm.notes" rows="3" cols="30" />
              </div>

              
            </div>
            <!-- Origin Sources  -->
            <div class="col-12 col-md card bg-light">
              <div class="field my-3">
                <label for="title">Origin </label>
                <!-- change for Dropdown 
                <InputText  
                  placeholder="Origin Name"
                  v-model.trim="newOriginForm.originName"
                /> -->
                <!-- change optionValue="originId" options to origin -->
                <Dropdown
                  v-model="newDocumentForm.originId"
                  :options="origin"
                  optionLabel="originName"
                  option-value="originId"
                  placeholder="Origin Name"
                />

              </div>

              <div class="field my-3">
                <label for="title">Origin Document Id</label>
                <!-- ToDo Ammend to originDocumentId Done -->
                <InputText
                  placeholder="Origin DocumentId"
                  v-model.trim="newDocumentForm.originDocumentId"
                />

              </div>
              
              <div class="field my-3">
                <label for="title">Origin Company Id</label>
                <InputText
                  placeholder="Origin CompanyId"
                  v-model.trim="newDocumentForm.originCompanyId"
                />
              </div>
            </div>
          </div>
          <!-- Contents -->
          <Divider align="left">
            <div class="inline-flex align-items-center">
              <span class="p-tag">CONTENTS</span>
              <!-- <Button 
                class="p-button-raised p-button-rounded p-button-help mx-3" 
                icon="pi pi-question-circle" 
                @click="goToHeaderPage('documentEdit-section2')" 
              /> -->
            </div>
          </Divider>

          <div class="row" style="margin-left: 25px; margin-top: 1rem;">
            <div class="col-12 col-md-6 card">
              <div class="field my-3">
                <label for="websiteUrl">Website url</label>
                <InputText
                  id="websiteUrl"
                  v-model.trim="newDocumentForm.websiteUrl"
                  autofocus
                />
              </div>
            </div>

            <div class="col-12 col-md-6 card">
              <div class="field my-3">
                <label for="websiteUrl">Files</label>
                <FileUpload
                  name="files"
                  mode="basic"
                  :customUpload="false"
                  @uploader="onUpload"
                  uploadLabel="Use these files"
                  @select="fileChange"
                  :multiple="false"
                  :maxFileSize="10000000"
                  accept="application/pdf"
                >
                  <template #empty>
                    <p>Drag and drop files to here to upload.</p>
                  </template>
                </FileUpload>
              </div>
            </div>
          </div>
          
          <div class="row" style="margin-left: 20px;">
            <div class="col-12">
              <div class="field my-3">
                <Panel  :toggleable="true" :collapsed="isCollapsed" >
                  <template #header >
                      <div style="padding: 0.8% 0.8% 0.8% 2.6%; width: 100%; height: 100%; " @click="isCollapsed = !isCollapsed" >
                        <strong style="margin: 0;"> Text</strong>
                      </div>
                  </template>

                  <QuillEditor
                    theme="snow"
                    toolbar="full"
                    v-model:content="newDocumentForm.text"
                    contentType="html"
                    :style="{ 'min-height': '200px' }"
                  />
                </Panel>

              </div>
            </div>
          </div>

          <div class="row" style="margin-left: 20px;">
            <div class="field my-3">
              <label for="notes">Contract summary for "Contracts per Company Overview"</label>
                <Textarea
                  id="contract"
                  v-model.trim="newDocumentForm.contract"
                  rows="5"
                  cols="30"
                />
              </div>
          </div>
          
          <!-- RelationShips -->
          <Divider align="left">
            <div class="inline-flex align-items-center">
              <span class="p-tag">RELATIONSHIPS</span>
              <!-- <Button 
                class="p-button-raised p-button-rounded p-button-help mx-3" 
                icon="pi pi-question-circle" 
                @click="goToHeaderPage('documentEdit-section3')" 
              /> -->
            </div>
          </Divider>

          <div class="row" style="margin-left: 20px; margin-bottom: 5rem;">
            <!-- Topic Button -->
            <div class="col-12 col-sm-12 col-md-4">
              <div class="my-3">
                <Button
                  icon="pi pi-plus"
                  label="TOPICS"
                  class="p-button-success"
                  @click="addTopic"
                />
                <template v-for="(topic, index) in documentTopics" :key="topic.topicId">
                  <div class="d-flex my-3 ms-3">
                    <Button
                      icon="pi pi-minus"
                      class="p-button-danger p-button-rounded me-3"
                      @click="removeTopic(topic.topicId , index)"
                    />
                    <Dropdown
                      v-model="topic.topicId"
                      :options="topics"
                      optionLabel="name"
                      optionValue="topicId"
                      placeholder="Select topic"
                      style="width: 70%"
                      :filter="true"
                    />
                  </div>
                </template>
              </div>
            </div>
            <!-- Country Button -->
            <div class="col-12 col-sm-12 col-md-4">
              <div class="my-3">
                <Button
                  icon="pi pi-plus"
                  label="COUNTRY"
                  class="p-button-warning"
                  @click="addCountry"
                />
                <template v-for="country in documentCountries" :key="country.countryId">
                  <div class="d-flex my-3 ms-3">
                    <Button
                      icon="pi pi-minus"
                      class="p-button-danger p-button-rounded me-3"
                      @click="removeCountry(country.countryId)"
                    />
                    <Dropdown
                      v-model="country.countryId"
                      :options="countries"
                      optionLabel="name"
                      optionValue="countryId"
                      :filter="true"
                      placeholder="Select country"
                      style="width: 70%"
                    />
                  </div>
                </template>
              </div>
            </div>
            <!-- Company Button -->
            <div class="col-12 col-sm-12 col-md-4">
              <div class="my-3">
                <Button
                  icon="pi pi-plus"
                  label="COMPANY"
                  class="p-button-help"
                  @click="addCompany"
                />
                <template v-for="company in documentCompanies" :key="company.companyId">
                <div class="d-flex my-3 ms-3">
                  <div v-if="!company.companyId" >  
                    <DocumentCreateCompany 
                      :countries="countries"
                      :company="company"
                      @addLastCompany="addLastCompany"
                    />    
                  </div>        
                  <Button
                    icon="pi pi-minus"
                    class="p-button-danger p-button-rounded me-3"
                    @click="removeCompany(company.companyId)"
                  />
                  <Dropdown
                    v-model="company.companyId"
                    :options="companies"
                    optionLabel="name"
                    optionValue="companyId"
                    placeholder="Select company"
                    :filter="true"
                    style="width: 70%"
                  />
                  </div>
                </template>
              </div>
            </div>
          </div>

          <template v-if="documentTopics && documentTopics.length > 0">
            <!-- topics -->
            <Divider align="left" >
              <div class="inline-flex align-items-center">
                <span class="p-tag">TOPICS</span>
                <!-- <Button 
                  class="p-button-raised p-button-rounded p-button-help mx-3" 
                  icon="pi pi-question-circle" 
                  @click="goToHeaderPage('documentEdit-section4')" 
                /> -->
              </div>
            </Divider>

            <div class="row" style="margin-left: 20px; margin-top: 20px; ">
              <div
                class="col-12 card"
                style="padding: 10px 20px; margin-bottom: 2rem;"
                v-for="(topic , index) in documentTopics"
                :key="topic.topicId"
              >
                <DocumentTopic
                  :topic="topic"
                  :topics="topics"
                  :isEditDocumentTopic="false"
                  @updateTopic="updateTopic"
                  :index="index"
                  @saveDocument="createDocumentWithRedirectProgram"
                  @redirectProgram="redirectProgram"
                  @syncCountryCompanyWithProgram="syncCountryCompanyWithProgram"
                >
                </DocumentTopic>
                <div style="margin-top: 1rem;">
                  <Carousel 
                    @getMarketValue="getMarketValue" 
                    :index="index"
                   />
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div v-if="createDocumentError" class="row">
      <div class="col-12 p-4">
        <div class="p-fluid container-fluid">
          <h5 style="color: red;">- {{ createDocumentErrorMessage  }} </h5>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 p-4">
        <div class="d-flex justify-content-end px-3" style="gap: 1rem;">
          <Button 
            class="p-button"  
            :loading="createDocumentIsLoading"
            :disabled="
              this.v$.newDocumentForm.$invalid || createDocumentIsLoading
            "
            @click="createDocumentPage()"
          >
            <label style="margin-right: 0.5rem;">Create Document</label>
            <font-awesome-icon icon="fa-regular fa-floppy-disk" />
          </Button>

          <Button
            class="p-button-success"
            :loading="createDocumentIsLoading"
            :disabled="
              this.v$.newDocumentForm.$invalid || createDocumentIsLoading
            "
            @click="createDocumentPageGoBack()"
          >
            <label style="margin-right: 0.5rem;">Create Document and Go Back</label>
            <font-awesome-icon icon="fa-regular fa-floppy-disk-circle-arrow-right" />
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formatToGlobalDate from '@/shared/helpers/date.helper';
import { createFormData ,createFormData2  } from '@/shared/helpers/formData.helper';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
// import MultiSelect from 'primevue/multiselect';
import Divider from 'primevue/divider';
import Panel from 'primevue/panel';
import Textarea from 'primevue/textarea';
import FileUpload from 'primevue/fileupload';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { QuillEditor } from '@vueup/vue-quill';
import Calendar from 'primevue/calendar';
import { useToast } from 'vue-toastification';
import DocumentTopic from '../components/DocumentTopic.vue';
// import DocumentCountry from '../components/DocumentCountry.vue';
// import DocumentCompany from '../components/DocumentCompany.vue';
import DocumentCreateCompany from '../components/DocumentCreateCompany.vue';
import Carousel from '../components/Carousel.vue';
import redirectHelperPage from '../../../../../../shared/helpers/redirectToHelperPage';

import { createDocument as createDocumentService } from '../../../../document/services/document.service';

const toast = useToast();

export default {
  name: 'NewDocument',
  components: {
    Button,
    InputText,
    QuillEditor,
    Dropdown,
    Textarea,
    FileUpload,
    // MultiSelect,
    Divider,
    Panel,
    Calendar,
    DocumentTopic,
    // DocumentCountry,
    // DocumentCompany,
    DocumentCreateCompany,
    Carousel,
  },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      loading: true,
      createDocumentIsLoading: false,
      createDocumentDialog: false,
      documentId: null,
      files: [],
      newDocumentForm: {
        documentSourceTypeId: null, // sourceId amend to DocumentSourceTypeId Done
        documentSourcename: null, // sourceName amend to DocumentSourcename Done
        applications: null,
        // companyId: null,
        file: null,
        notes: null,
        text: null,
        title: null,
        websiteUrl: null,
        documentDate: null,
        originDocumentId: null, // originalSourceId amend to OriginDocumentId Done
        
        // add new Columns
        originId: null, //on originTable 
        originCompanyId : null, 
      },
      //newTable Origin 
      // newOriginForm: {
      //   originName: null,
      //   originId: null,
      // },

      createCompanyDialog: false,
      uniqueOptions: [],
      documentTopics: [],
      documentCountries: [],
      documentCompanies: [],
      marketValues: [],
      regionCountries: [],
      isCollapsed: true,

      //RedirectDocument
      redirectDocumentId: null, 

      createDocumentError: false,
      createDocumentErrorMessage: '',
    };
  },
  validations() {
    return {
      newDocumentForm: {
        title: { required },
        documentDate: {required}
        // notes: { required },
        // text: { required },
        // websiteUrl: { required },
        // documentSourceTypeId: { required }, // sourceId amend to DocumentSourceTypeId
        // companyId: { required },
        // applications: { required },
      },
    };
  },
  async created() {
    await this.getDocumentApplications();
    await this.getDocumentSources();
    await this.getOrigin();
    await this.getCompanies();
    await this.getCountries();
    await this.getTopics();
    if(this.selectedTopic){
      this.documentTopics.push({
        topicId: this.selectedTopic.topicId,
      })
    }
    if(this.companyIdForCreateDocument){
      // ADD COMPANY FROM COMPANY LIST 
      this.documentCompanies.pop();
      this.documentCompanies.push({ "companyId": this.companyIdForCreateDocument})
    }
  },
  beforeUnmount() { // clean companyIdForCreateDocument 
    this.$store.dispatch('resetUpdateCompanyForCreateDocument');
  },
  computed: {
    selectedTopic() {
      return this.$store.getters.obtainSelectedTopic;
    },
    companyIdForCreateDocument(){ // THIS COMPANYIDFORCREATEDOCUMENT CAME FORM COMPANY ANALYST LIST 
      return this.$store.getters.obtainCompanyForCreateDocument;
    },
    documentApplications() {
      const applications = this.$store.getters.obtainDocumentApplications;
      applications.forEach((application) => {
        if (
          application.name == 'Forecast - Production' ||
          application.name == 'Forecast - Requirement'
        ) {
          this.uniqueOptions.push(application);
        }
      });
      return applications;
    },
    documentSources() {
      let documentSourcesSort = this.$store.getters.obtainDocumentSources
      documentSourcesSort.sort(function (a,b){
        if(a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
        if(a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
        return 0;
      }); 
      return documentSourcesSort;
    },
    companies() {
      let companiesOrder = this.$store.getters.obtainCompanies 

      companiesOrder.sort(function (a,b){
        if(a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
        if(a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
        return 0;
      });
      
      return companiesOrder;
    },
    topics() {
      let topicsOrder = this.$store.getters.obtainTopics 

      topicsOrder.sort(function (a,b){
        if(a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
        if(a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
        return 0;
      });

      return topicsOrder;
    },
    countries(){
      const x = this.$store.getters.obtainCountries
      return x.sort((a,b) => a.name.localeCompare(b.name))
    },
    origin(){
      // change to origin getter
      // Origin is located to Document, when origin expands make new vuex
      let originOrder = this.$store.getters.obtainOrigin
      originOrder.sort(function (a,b){
        if(a.originName.toLowerCase() < b.originName.toLowerCase()) { return -1; }
        if(a.originName.toLowerCase() > b.originName.toLowerCase()) { return 1; }
        return 0;
      }); 
      return originOrder;
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },

    goToHeaderPage(section){
      redirectHelperPage(section)
    },

    addTopic() {
      this.documentTopics.push({
        topicId: '',
      });
    },
    removeTopic(topicId , index) {
      // this.documentTopics = this.documentTopics.filter(
      //   (t) => t.topicId != topicId
      // );
      this.documentTopics = this.documentTopics.filter((t , i) =>
        i!= index
      )
    },

    addCountry() {
      this.documentCountries.push({
        countryId: '',
      });
    },
    removeCountry(countryId) {
      this.documentCountries = this.documentCountries.filter(
        (country) => country.countryId != countryId
      );
    },
    addCountrySyncWithProgram(countryId){
      this.documentCountries.pop();
      this.documentCountries.push({"countryId":countryId})
    },

    addCompany() {
      this.documentCompanies.push({
        companyId: '',
      });
    },
    removeCompany(companyId) {
      this.documentCompanies = this.documentCompanies.filter(
        (company) => company.companyId != companyId
      );
    },
    addLastCompany(){ // when created new Company add last created 
      this.documentCompanies.countries.pop();
      this.documentCompanies.push({ "companyId":this.companies[this.companies.length-1].companyId})
    },
    addCompanySyncWithProgram(companyId){
      this.documentCompanies.pop();
      this.documentCompanies.push({ "companyId": companyId})
    },

    syncCountryCompanyWithProgram(companyAndCountry){
      if(companyAndCountry.manufactured){
        this.addCompany(); 
        this.addCompanySyncWithProgram(companyAndCountry.manufactured)
      }
      // if(companyAndCountry.contryManufactured){
      //   const countryId = this.countries.find(country => country.name === companyAndCountry.contryManufactured)
      //   this.addCountry();
      //   this.addCountrySyncWithProgram(countryId.countryId);
      // }
      if(companyAndCountry.countryId){
        const countryId = companyAndCountry.countryId
        this.addCountry();
        this.addCountrySyncWithProgram(countryId);
      }
    },

    validateShowOptions() {
      if (this.newDocumentForm.applications) {
        this.validateApplicationOptions({
          value: this.newDocumentForm.applications,
        });
      }
      if (this.editDocumentForm.applications) {
        this.validateApplicationOptions({
          value: this.editDocumentForm.applications,
        });
      }
    },
    validateApplicationOptions($event) {
      const selectedOptions = $event.value;
      if (selectedOptions.length == 0 || !selectedOptions.includes()) {
        const requirement = this.documentApplications.find(
          (application) => application.name == 'Forecast - Requirement'
        );
        const optionReq = document.querySelector(
          '#application-option-' + requirement.applicationTypeId
        );
        if (optionReq) {
          const li = optionReq.parentElement;
          li.style.display = 'flex';
        }
        const production = this.documentApplications.find(
          (application) => application.name =='Forecast - Production'
        );
        const optionProd = document.querySelector(
          '#application-option-' + production.applicationTypeId
        );
        if (optionProd) {
          const li = optionProd.parentElement;
          li.style.display = 'flex';
        }
      }
      selectedOptions.forEach((selectedOption) => {
        if (
          selectedOption ==
          this.uniqueOptions.find((a) => a.name == 'Forecast - Production')
            .applicationTypeId
        ) {
          const requirement = this.uniqueOptions.find(
            (a) => a.name == 'Forecast - Requirement'
          );
          const option = document.querySelector(
            '#application-option-' + requirement.applicationTypeId
          );
          if (option) {
            const li = option.parentElement;
            li.style.display = 'none';
          }
        }
        if (
          selectedOption ==
          this.uniqueOptions.find((a) => a.name == 'Forecast - Requirement')
            .applicationTypeId
        ) {
          const production = this.uniqueOptions.find(
            (a) => a.name == 'Forecast - Production'
          );
          const option = document.querySelector(
            '#application-option-' + production.applicationTypeId
          );
          if (option) {
            const li = option.parentElement;
            li.style.display = 'none';
          }
        }
      });
    },
    async getDocumentApplications() {
      try {
        this.loading = true;
        await this.$store.dispatch('obtainDocumentApplications');
        // toast.success('Document Applications obtained successfully.');
        this.loading = false;
      } catch (error) {
        toast.error(error.message);
        this.loading = false;
      }
    },
    async getDocumentSources() {
      try {
        this.loading = true;
        await this.$store.dispatch('obtainDocumentSources');
        // toast.success('Document Sources obtained successfully.');
        this.loading = false;
      } catch (error) {
        toast.error(error.message);
        this.loading = false;
      }
    },
    async getOrigin(){
      try {
        this.loading = true; 
        await this.$store.dispatch('obtainOrigin');
        this.loading = false
      } catch (error) {
        // toast.error(error.message)
        this.loading = false;
      }
    },
    async getCompanies() {
      try {
        this.loading = true;
        await this.$store.dispatch('obtainCompanies');
        // toast.success('Companies obtained successfully.');
        this.loading = false;
      } catch (error) {
        toast.error(error.message);
        this.loading = false;
      }
    },
    async getTopics() {
      try {
        this.loading = true;
        await this.$store.dispatch('obtainTopics');
        this.loading = false;
      } catch (error) {
        toast.error(error.message);
        this.loading = false;
      }
    },
    async getCountries() {
      try {
        this.loading = true;
        await this.$store.dispatch('obtainCountries');
        this.loading = false;
      } catch (error) {
        toast.error(error.message);
        this.loading = false;
      }
    },
    formatDate(date) {
      if (!date) return '';
      return formatToGlobalDate(date);
    },
    
    async createDocumentPage(){
      await this.createDocument();
      // if !created show message
      if(!this.createDocumentError){
        toast.success('Document created!');
        this.$router.push(`./edit/${this.redirectDocumentId}`);
      }
    },
    
    async createDocumentPageGoBack(){
      await this.createDocument();
      //  if !created show message
      if(!this.createDocumentError){
        toast.success('Document created!');
        this.goBack();
      }
    },

    async createDocumentWithRedirectProgram(redirectDocumentProgramTopicId){
      await this.createDocument();
      if(!this.createDocumentError){
        toast.success('Document created!');
        this.$router.push(`../program/overview/${redirectDocumentProgramTopicId}`)
        // window.open(`../program/overview/${redirectDocumentProgramTopicId}`, '_blank')
      }
    },

    redirectProgram(redirectDocumentProgramTopicId){
      this.$router.push(`../program/overview/${redirectDocumentProgramTopicId}`)
    },
    
    async createDocument() {
      try {
        this.createDocumentError = false; 
        this.createDocumentErrorMessage = '';

        if (!this.v$.newDocumentForm.$invalid) {
          this.createDocumentIsLoading = true;
          let documentForm = new FormData();

          documentForm.append('file', this.files.length > 0 ? this.files[0] : null);
          for (let key of Object.keys(this.newDocumentForm)) {
            if (this.newDocumentForm[key]) {
              documentForm.append(key, this.newDocumentForm[key]);
            }
          }
          if(this.newDocumentForm.applications != null) {
            documentForm.set('applicationsString', this.newDocumentForm.applications);
          }
          documentForm.set('documentDate', `${this.newDocumentForm.documentDate.getFullYear()}-${this.newDocumentForm.documentDate.getMonth()+1}-${this.newDocumentForm.documentDate.getDate()}T00:00:00`);
          documentForm.delete('applications');

          // this.documentTopics.forEach(t => {
          //   t.startYear = (new Date(t.startYear)).getFullYear();
          //   t.endYear = (new Date(t.endYear)).getFullYear();
          //   // Unify segmentationItems array
          //   const segmentationItems = []
          //   t.segmentationItems.forEach(array => {
          //     segmentationItems.push(...array);
          //   })
          //   t.segmentationItems = segmentationItems;
          // });
          
          createFormData(documentForm, 'documentTopics', this.documentTopics);
          createFormData2(documentForm, 'countryIds', this.documentCountries)
          createFormData2(documentForm, 'companyIds', this.documentCompanies);

          //await this.$store.dispatch('addNewDocument', documentForm);
          const response = await createDocumentService(documentForm);
          this.redirectDocumentId =  response.data.documentId;

          this.createDocumentIsLoading = false;
        } else {
          toast.error('Fill Required Data');
          this.createDocumentError = true;
          this.createDocumentErrorMessage = 'Fill Required Data' 
        }
      } catch (error) {
        this.createDocumentError = true;
        this.createDocumentErrorMessage = error.message
        this.createDocumentIsLoading = false;
        toast.error(error.message);
      }
    },
    onUpload() {
      toast.success('Successfully selected.');
    },
    fileChange($event) {
      this.files = $event.files;
    },
    updateTopic({documentTopic , index}) {
      // const topicIndex = this.documentTopics.findIndex(
      //   (t) => t.topicId == documentTopic.topicId
      // );
      // console.log(documentTopic);
      // console.log(index);
      this.documentTopics[index] = {
        ...this.documentTopics[index],
        ...documentTopic,
      };
      // console.log(this.documentTopics[topicIndex])
    },
    
    // getObjectFor carousel
    // getMarketValue({object , index}){
    //   console.log(index)
    //   if(this.documentTopics[0].segmentationItems){
    //     let b=  object.flat().map(item=> ({
    //       ...item,
    //       "segmentationItemIds": this.documentTopics[0].segmentationItems
    //     }));
    //     this.marketValues=b;
    //     console.log('market ', this.marketValues);
    //   }
    // },

    // for the future feature 
    getMarketValue({object , index}){
      let b = object.flat().map(item => ({
          ...item,
        }))
      this.documentTopics[index] = {
          ...this.documentTopics[index],
          "documentTopicMarketValues" : b
      }
      // console.log(this.documentTopics[index])
    },
  },
  watch: {
    // 'documentTopics': {
    //   handler(newVal) {
    //     try{
    //       if (newVal) {
    //         const newTopicId = newVal[newVal.length - 1].topicId;
    //         const prevArray = newVal.slice(0, newVal.length - 1)
    //         const topicExists = prevArray.findIndex(t => t.topicId == newTopicId) >= 0;
    //         if (topicExists) {
    //           this.documentTopics.pop();
    //         }
    //       }
    //     }
    //     catch{
    //       null
    //     }
    //   },
    //   deep: true,
    // },
    'documentCountries': {
      handler(newVal) {
        try{
          if (newVal) {
            const newCountryId = newVal[newVal.length - 1].countryId;
            const prevArray = newVal.slice(0, newVal.length - 1)
            const countryExists = prevArray.findIndex(t => t.countryId == newCountryId) >= 0;
            if (countryExists) {
              this.documentCountries.pop();
            }
          }
        }
        catch{
          null
        }
      },
      deep: true,
    },
    'documentCompanies': {
      handler(newVal) {
        try{
          if (newVal) {
            const newCompanyId = newVal[newVal.length - 1].companyId;
            const prevArray = newVal.slice(0, newVal.length - 1)
            const companiesExists = prevArray.findIndex(t => t.companyId == newCompanyId) >= 0;
            if (companiesExists) {
              this.documentCompanies.pop();
            }
          }
        }
        catch{
          null
        }
      },
      deep: true,
    },
  }
};
</script>

<style>
.p-panel-header{
  padding: 0 !important;
}
.p-datepicker-month {
    background: #673AB7;
    color: white !important;
    /* text-decoration: underline; */
}
.p-datepicker-month:hover{
    background: blue;
}
.p-datepicker-year {
    background: #673AB7;
    color: white !important;
    /* text-decoration: underline; */
}
.p-datepicker-year:hover {
    background: blue;
}

</style>
